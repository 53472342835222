<template>
  <div>
    <nav
      class="navbar navbar-light navbar-expand-sm"
      aria-label="Navigation"
      :style="{
        'margin-left': sidebarShown ? '-220px' : 0,
        background: style.headerBackgroundColor
      }"
    >
      <a
        class="pointer order-first sidebar-toggle"
        v-if="authenticated && user && dashboardData && !dashboardData.isOnboardingFinished"
        aria-label="Menu"
      >
        <button
          id="menu-button"
          class="material-icons mt-1 float-left pointer menu-button"
          :style="{ color: style.headerIconColor }"
          v-if="menu && menu.length"
          v-on-clickaway="outsideMenuClick"
          @click="toggleSidebar"
          :aria-expanded="String(sidebarShown)"
          aria-label="Menu"
          @keydown="focusOnNavbar"
          type="button"
        >
          <span v-if="!sidebarShown">menu</span>
          <span v-else>close</span>
        </button>
      </a>
      <router-link tag="div" class="mt-1 ml-3 navbar-home pointer d-sm-block" :to="{ name: 'dashboard' }">
        <button
          aria-label="info"
          class="pointer material-icons float-left"
          :style="{ color: style.headerIconColor }"
          v-if="authenticated && user"
        >
          home
        </button>
      </router-link>

      <router-link
        tag="div"
        v-if="!onDashboard && !company.logoURL && !company.tagline && $route.meta && !$route.meta.public"
        class="pointer mt-1 back-to-dashboard pl-0 ml-2 ml-sm-4 ml-md-4 ml-lg-4"
        :to="{ name: 'dashboard' }"
        ><button class="material-icons">keyboard_backspace</button>
        <span>{{ 'general.returnToDashboard' | translate }}</span>
      </router-link>

      <div class="navbar-brand pl-0 ml-2 ml-sm-4 ml-md-4 ml-lg-4" name="logo" aria-label="logo" v-if="showLogo">
        <img :src="company.logoURL" width="auto" height="100%" alt @click="hideTasksDropdown()" />
      </div>
      <p class="navbar-text tagline">{{ company.tagline }}</p>
      <div class="d-flex align-items-center justify-content-center ml-auto">
        <i
          data-v-step="1"
          v-if="isAdmin && !loadingFilters && filters.length"
          class="material-icons d-block pointer mr-2 mr-md-3 ml-auto"
          @click="showFilterModal"
          :style="{ color: style.headerIconColor }"
          >filter_list</i
        >
        <button
          @click="showInfo"
          aria-label="Info"
          class="pointer material-icons d-block pointer d-sm-none collapsed mr-2 mr-md-3"
          :style="{ color: style.headerIconColor }"
        >
          info_outline
        </button>
        <button
          class="pointer material-icons d-block pointer d-sm-none collapsed mr-md-3"
          :disabled="!tasks.length"
          :class="{
            'icon-badge mr-3': todos.length,
            'mr-2': !todos.length,
            pointer: tasks.length
          }"
          :data-count="todos.length"
          aria-label="Notifications"
          @click="toggleTasks"
          v-if="authenticated && user"
          :style="{ color: style.headerIconColor }"
        >
          <template v-if="!tasks.length">notifications_none</template>
          <template v-if="todos.length">notifications_active</template>
          <template v-if="tasks.length && !todos.length">notifications</template>
        </button>
        <button
          class="pointer material-icons d-block d-sm-none collapsed ml-md-1 ml-0"
          @click="toggleMobileUserMenu"
          aria-label="Account"
          v-on-clickaway="outsideMobileUserMenuClick"
          v-if="authenticated && user"
          :style="{ color: style.headerIconColor }"
        >
          account_circle
        </button>
      </div>
      <div id="notifications" v-if="authenticated">
        <button id="allowNotifications" style="border: 1px solid black; cursor: pointer">Allow notifications</button>
        <div v-if="user" id="notificationsEnabled" style="display: none">{{ clientNotifications() }}</div>
        <div id="notificationsBlocked" style="display: none">Notifications Blocked</div>
      </div>
      <div class="collapse navbar-collapse" style="z-index: 10" :class="{ show: showMobileMenu || showMobileUserMenu }">
        <ul class="nav navbar-nav ml-auto" v-if="authenticated && user">
          <li class="nav-item">
            <a class="pointer d-none d-sm-block" @click="showInfo">
              <button
                aria-label="info"
                class="pointer material-icons float-left"
                :style="{ color: style.headerIconColor }"
              >
                info_outline
              </button>
            </a>
          </li>
          <li class="nav-item ml-3">
            <a class="pointer d-none d-sm-block" @click="toggleTasks">
              <button
                class="material-icons float-left"
                :disabled="!tasks.length"
                aria-label="Notifications"
                :class="{ 'icon-badge': todos.length, pointer: tasks.length }"
                :data-count="todos.length"
                :aria-haspopup="todos.length > 0 ? true : false"
                :aria-expanded="String(showTasks)"
                v-if="authenticated && user"
                :style="{ color: style.headerIconColor }"
              >
                <template v-if="!tasks.length">notifications_none</template>
                <template v-if="todos.length">notifications_active</template>
                <template v-if="tasks.length && !todos.length">notifications</template>
              </button>
            </a>
            <ul
              class="dropdown-menu tasks-dropdown-menu dropdown-menu-right py-0"
              aria-label="tasks-dropdown"
              :class="{ show: showTasks }"
            >
              <tasks-dropdown></tasks-dropdown>
            </ul>
          </li>
          <li class="dropdown nav-item ml-3" v-if="!hideUserMenu">
            <a class="d-none d-sm-block" v-on-clickaway="outsideUserMenuClick" @click="toggleUserDropdown">
              <!-- <img v-if="user.imageURL" height="30" width="30" :src="user.imageURL" alt="profile-image" /> -->
              <button
                class="pointer material-icons float-left"
                aria-label="Account"
                :style="{ color: style.headerIconColor }"
                aria-haspopup="true"
                :aria-expanded="String(userDropdown)"
              >
                account_circle
              </button>
            </a>
            <ul
              class="pointer dropdown-menu user-dropdown-menu dropdown-menu-right"
              aria-label="user-dropdown"
              :class="{ show: userDropdown || showMobileUserMenu }"
            >
              <router-link tag="li" class="dropdown-item dashboard" :to="{ name: 'dashboard' }">
                <a class="text-muted" :class="{ selected: $route.name === 'dashboard' }">{{
                  'dashboard.title' | translate
                }}</a>
              </router-link>
              <router-link tag="li" :to="{ name: 'profile' }" class="dropdown-item pointer" v-if="!isKeeper">
                <a class="text-muted" :class="{ selected: $route.name === 'profile' }">{{
                  'menu.settings' | translate
                }}</a>
              </router-link>
              <li @click="logout" class="dropdown-item logout pointer">
                <a class="text-muted">{{ 'menu.signOut' | translate }}</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>

    <div
      class="collapse navbar-collapse tasks-collapse tasks-dropdown-menu px-2"
      :class="{
        show: showTasks,
        'pt-4 pb-3 on-dashboard': $route.path === '/dashboard'
      }"
    >
      <tasks-dropdown />
    </div>
    <sweet-modal
      :title="$t('lp.filters')"
      modal-class="mt-5"
      hide-footer
      ref="filterModal"
      :blocking="true"
      class="filters-sweet-modal"
    >
      <div
        v-if="filters && filters.length > 0"
        class="filters-modal-container"
        style="font-family: Open Sans; color: #26253E;"
      >
        <filters-talentech
          :filters="filters | stringify"
          :selectedFilterItems="selectedFilterItems | stringify"
          selectedTab="0"
          @filtersChanged="onFiltersChanged($event)"
        ></filters-talentech>
      </div>
      <primary-button class="float-right" @click="updateFilters" style="margin-top: auto;">{{
        'settings.save' | translate
      }}</primary-button>
    </sweet-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import each from 'lodash/each';
import { SweetModal } from 'sweet-modal-vue';

import { mixin as clickaway } from 'vue-clickaway';
import TasksDropdown from '@app/components/ui/TasksDropdown';
import Sidebar from '@app/components/ui/Sidebar';
import cloneDeep from 'lodash/cloneDeep';
import jwtDecode from 'jwt-decode';

const { VUE_APP_ADMIN_API_URL } = process.env;

const mqlMD = window.matchMedia('(min-width: 768px)');

import { OptionGroup, Option, Select } from 'element-ui';
import tasks from '@app/mixins/tasks';
import planhatMixin from '../../mixins/planhat';
const Multiselect = () => import('vue-multiselect');

export default {
  components: {
    SweetModal,
    ElOptionGroup: OptionGroup,
    ElOption: Option,
    ElSelect: Select,
    TasksDropdown,
    Multiselect,
    Sidebar
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    hideUserMenu: {
      type: Boolean,
      default: false
    }
  },
  mixins: [clickaway, tasks, planhatMixin],
  computed: {
    ...mapGetters('settings', ['company', 'style', 'sidebarShown']),
    ...mapGetters('auth', ['user', 'authenticated', 'isAdmin', 'templateId', 'isKeeper']),
    ...mapGetters('dashboard', ['dashboardData', 'menu', 'isOnboarding']),
    showLogo() {
      if (this.company && this.$route.meta && this.$route.meta.public) {
        return !this.company.hideLogo;
      }
      return true;
    },
    onDashboard() {
      return this.$route.name === 'dashboard';
    }
  },
  data() {
    return {
      userDropdown: false,
      loadingFilters: false,
      menuDropdown: false,
      showTasks: false,
      showMobileUserMenu: false,
      showMobileMenu: false,
      filters: [],
      selectedFilters: [],
      selectedFilterItems: [],
      userFilters: null
    };
  },
  created() {
    if (this.isAdmin && this.$store.state.auth.token && this.$store.state.auth.templateId) {
      this.getFilters();
    }
  },
  methods: {
    clientNotifications() {
      return `${JSON.parse(window.localStorage.getItem('user')).id}-${window.localStorage.getItem('fingerprint')}`;
    },
    showFilterModal() {
      this.$refs.filterModal.open();
    },
    onFiltersChanged(event) {
      this.selectedFilters = event.detail.value;
    },
    toggleSidebar($event) {
      $event.preventDefault();
      $event.stopPropagation();
      this.$store.dispatch('settings/toggleSidebar');
      this.hideTasksDropdown();
    },
    checkMqlMD($event) {
      this.hideTasksDropdown();
      this.showMobileUserMenu = false;
      this.showMobileMenu = false;
    },
    toggleUserDropdown() {
      this.userDropdown = !this.userDropdown;
      this.hideTasksDropdown();
    },
    toggleMenu() {
      this.menuDropdown = !this.menuDropdown;
      this.hideTasksDropdown();
    },
    hideTasksDropdown() {
      this.showTasks = false;
    },
    hideUserMenuDropdown() {
      this.userDropdown = false;
    },
    toggleTasks() {
      if (!this.tasks.length) {
        return (this.showTasks = false);
      }
      this.showTasks = !this.showTasks;
    },
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu;
      this.hideTasksDropdown();
    },
    toggleMobileUserMenu() {
      this.showMobileUserMenu = !this.showMobileUserMenu;
      this.hideTasksDropdown();
    },
    logout() {
      this.$store.dispatch('auth/logout', true).then(
        () => {
          //Planhat logout event, description is optional and can be changed to relevant info
          this.track('tal_logout_attempt_succeeded');
          window.location.href = '/auth/login';
        },
        (error) => {
          this.track('tal_logout_attempt_failed');
        }
      );
      // this.$router.push({ name: "login" });
    },
    outsideUserMenuClick() {
      this.userDropdown = false;
    },
    outsideMenuClick() {
      this.menuDropdown = false;
    },
    outsideMobileMenuClick() {
      this.showMobileMenu = false;
    },
    outsideMobileUserMenuClick() {
      this.showMobileUserMenu = false;
    },
    showInfo() {
      let translationKey = 'info.dashboard';
      switch (this.$route.name) {
        case 'introduction':
          if (this.isOnboarding) {
            translationKey = 'info.introduction';
          } else {
            translationKey = 'info.introductionOffboarding';
          }
          break;
        case 'companyPageContent':
        case 'companyPageOverview':
          if (this.isOnboarding) {
            translationKey = 'info.company';
          } else {
            translationKey = 'info.companyOffboarding';
          }
          break;
        case 'pulseCheckContent':
        case 'pulseCheckOverview':
          if (this.isOnboarding) {
            translationKey = 'info.pulseCheck';
          } else {
            translationKey = 'info.pulseCheckOffboarding';
          }
          break;
        case 'quizContent':
        case 'quizOverview':
          if (this.isOnboarding) {
            translationKey = 'info.quiz';
          } else {
            translationKey = 'info.quizOffboarding';
          }
          break;
        default:
          if (this.isOnboarding) {
            translationKey = 'info.dashboard';
          } else {
            translationKey = 'info.dashboardOffboarding';
          }
      }

      let message = this.$options.filters.translate(translationKey);
      if (message) {
        message = this.$createElement(
          Vue.compile('<div><p>' + message.replace(/(\r\n|\n|\r)/gm, '<br />') + '</p></div>')
        );
        this.$alert(message, {
          showConfirmButton: false,
          closeOnClickModal: true,
          closeOnPressEscape: true,
          customClass: 'large'
        }).catch(() => {});
        Vue.nextTick(() => {
          const getCloseBtn = document.querySelector('.el-message-box__headerbtn');
          getCloseBtn.focus();
        });
      }
      this.hideTasksDropdown();
    },
    updateFilters() {
      this.$refs.filterModal.close();
      this.loadingFilters = true;
      const filtersString = this.selectedFilters.join(',');
      localStorage.setItem('admin_filters', filtersString);
      setTimeout(() => {
        this.$store.commit('dashboard/clearTodoList');
        this.$store.dispatch('dashboard/getDashboard').then(() => {
          this.$store.dispatch('dashboard/getDashboardSections');

          this.$store
            .dispatch('tasks/getOnboardeeTasks')
            .then(() => this.$store.commit('dashboard/addToTodolist', this.$store.getters['tasks/onboardeeTasks']));
        });
      }, 1000);
      this.loadingFilters = false;
    },
    getFilters() {
      const templateId = this.templateId || this.user.onboardings[0].templateId;
      this.$http.get(`/v2/allowed-filters`).then((response) => {
        this.filters = response.data.filters;
        this.selectedFilterItems = this.filters
          .map((filter) => filter.items.map((filterItem) => filterItem))
          .flat()
          .map((e) => e.id);
      });
    },
    focusOnNavbar(event) {
      if (this.sidebarShown && event.key === 'Tab') {
        const keyboardfocusableElements = [
          ...document.querySelectorAll(
            'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
          )
        ].filter((el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'));
        setTimeout(() => {
          keyboardfocusableElements[0].focus();
        });
      }
    }
  },
  watch: {
    '$route.path'(newRoute) {
      this.hideUserMenuDropdown();
      this.hideTasksDropdown();
    },
    '$store.state.auth.templateId'(newVal, oldVal) {
      if (newVal !== oldVal && this.isAdmin && this.$store.state.auth.token) {
        Vue.nextTick(() => {
          this.getFilters();
        });
      }
    }
  },
  mounted() {
    mqlMD.addListener(this.checkMqlMD);
  },
  beforeDestroy() {
    mqlMD.removeListener(this.checkMqlMD);
  }
};
</script>

<style>
.el-select-dropdown {
  z-index: 9999 !important;
}
.filters-sweet-modal .sweet-modal .sweet-content-content {
  display: flex;
  flex-direction: column;
}

.filters-modal-container {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #60606c;
  --bs-gray-dark: #30303a;
  --bs-gray-50: #fff;
  --bs-gray-100: #f1f1f3;
  --bs-gray-200: #e6e6ea;
  --bs-gray-300: #d6d6dc;
  --bs-gray-400: #b7b7c0;
  --bs-gray-500: #757581;
  --bs-gray-600: #60606c;
  --bs-gray-700: #444450;
  --bs-gray-800: #30303a;
  --bs-gray-900: #151517;
  --bs-primary: #26253e;
  --bs-secondary: #757581;
  --bs-success: #49815f;
  --bs-info: #3c7ba4;
  --bs-warning: #9e6b18;
  --bs-danger: #ca4b46;
  --bs-light: #fff;
  --bs-dark: #26253e;
  --bs-primary-rgb: 38, 37, 62;
  --bs-secondary-rgb: 117, 117, 129;
  --bs-success-rgb: 73, 129, 95;
  --bs-info-rgb: 60, 123, 164;
  --bs-warning-rgb: 158, 107, 24;
  --bs-danger-rgb: 202, 75, 70;
  --bs-light-rgb: 255, 255, 255;
  --bs-dark-rgb: 38, 37, 62;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 38, 37, 62;
  --bs-body-bg-rgb: 241, 241, 243;
  --bs-font-sans-serif: 'Open Sans', Helvetica -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  --bs-gradient: linear-gradient(180deg, hsla(0, 0%, 100%, 0.15), hsla(0, 0%, 100%, 0));
  --bs-root-font-size: 16px;
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.4285714286;
  --bs-body-color: #26253e;
  --bs-body-bg: #f1f1f3;

  height: calc(100vh / 2) !important;
  max-height: calc(100vh / 2) !important;
  overflow-y: auto;
  margin-bottom: 20px;
}
</style>

<style lang="scss" scoped>
@import '~vue-multiselect/dist/vue-multiselect.min.css';
@import '@shared/styles/_font-sizes.scss';

@media (min-width: 768px) {
  .navbar-nav > li > a {
    padding-bottom: 14px;
  }
  .tasks-collapse {
    display: none !important;
  }
}

.multiselect__tags input {
  box-shadow: none !important;
}
.multiselect__input {
  border: none !important;
}

.multiselect--disabled {
  cursor: not-allowed;
  pointer-events: all;

  .multiselect__select {
    cursor: not-allowed;
  }
}

nav.navbar {
  -webkit-box-shadow: 0 1px 3px rgba(57, 63, 72, 0.3);
  -moz-box-shadow: 0 1px 3px rgba(57, 63, 72, 0.3);
  box-shadow: 0 1px 3px rgba(57, 63, 72, 0.3);

  .nav-item .material-icons {
    font-size: 26px;
  }
  button {
    padding: 1px 6px;
  }
  @media (min-width: 576px) {
    height: 64px;
    .navbar-brand img {
      max-height: 50px;
    }
  }
}

.tasks-collapse {
  padding: 0;

  .tasks-panel {
    margin: 0.5rem -16px 0;
    border-radius: 0;
  }
}

#app .navbar p.navbar-text.tagline {
  margin: 0 0.5rem;
}

.navbar .user-dropdown-menu {
  top: 44px;

  @media (max-width: 767px) {
    border: 0;
    padding-top: 12px;
  }
}
.navbar .tasks-dropdown-menu {
  top: 62px;
}
.navbar .dropdown-menu {
  border-radius: 0;
  border-color: #f1f0f0;
}

.navbar {
  z-index: 2001;
  -webkit-transition: margin 0.1s ease;
  -moz-transition: margin 0.1s ease;
  -o-transition: margin 0.1s ease;
  transition: margin 0.1s ease;
}
.navbar button {
  border: none;
  background: none;
  &:focus {
    outline: -webkit-focus-ring-color auto 1px;
  }
}

.icon-badge:after {
  content: attr(data-count);
  position: absolute;
  background: lightgrey;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  top: 5px;
  width: 19px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  font-size: 9px;
  border-radius: 50%;
  color: black;
  @media (min-width: 768px) {
    transform: translateX(-3px);
  }

  @media (max-width: 600px) {
    width: 12px;
    height: 11px;
    line-height: 11px;
    font-size: 8px;
    top: 5px;
  }
}

@media (max-width: 767px) {
  .navbar {
    justify-content: initial;
    .material-icons {
      // font-size: var(--subtitle-size);
      font-size: 24px;
    }
    .navbar-nav {
      margin: 0 -15px;
    }
    .navbar-brand {
      padding: 0 !important;
      margin-right: 0.1rem;
    }
    .navbar-home {
      margin-left: 0.25rem !important;
    }
  }

  .navbar-brand > img {
    max-height: 28px;
    @media (max-width: 600px) {
      max-height: 20px;
    }
  }

  #app .navbar p.navbar-text.tagline {
    font-size: 0.7rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 150px;
  }
}

.tasks-collapse:not(.on-dashboard) {
  margin-top: -0.5rem;
}

.back-to-dashboard {
  position: relative;
  width: 150px;
}

.back-to-dashboard span {
  vertical-align: super;
  margin-left: 0rem;
  margin-left: 3px;
  top: 4px;
  position: absolute;
}

.user-dropdown-menu {
  .selected {
    font-weight: bold;
  }
}
</style>

<style>
@media (max-width: 767px) {
  .el-message-box__wrapper {
    width: 100% !important;
    max-width: 100% !important;
  }
}
</style>
